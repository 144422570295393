import React from 'react';
import { Drawer, List, ListItem, ListItemText, ListItemIcon, IconButton, Typography, Box, Divider } from '@mui/material';
import { Dashboard, Person, Flight, Logout, Menu, Settings } from '@mui/icons-material';
import { styled } from '@mui/system';
import { Link } from 'react-router-dom';

const drawerWidth = 240;
const collapsedWidth = 60;

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(1),
  justifyContent: 'space-between',
}));

const Sidebar = ({ open, onClose, onToggle, collapsed }) => {
  return (
    <Drawer
      variant="persistent"
      anchor="left"
      open={open}
      sx={{
        width: open ? drawerWidth : collapsedWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: open ? drawerWidth : collapsedWidth,
          boxSizing: 'border-box',
        },
      }}
    >
      <DrawerHeader>
        {open && (
          <Typography variant="h6" noWrap>
            My Admin Panel
          </Typography>
        )}
        <IconButton onClick={onToggle}>
          <Menu />
        </IconButton>
      </DrawerHeader>
      <Divider />
      <List>
        <ListItem button component={Link} to="/">
          <ListItemIcon><Dashboard /></ListItemIcon>
          {open && <ListItemText primary="Dashboard" />}
        </ListItem>
        <Divider />
       
        {/* <ListItem button component={Link} to="/manage-locations">
          <ListItemIcon><Settings /></ListItemIcon>
          {open && <ListItemText primary="Manage Location" />}
        </ListItem>
        <Divider /> */}
        <ListItem button component={Link} to="/CMSPage">
          <ListItemIcon><Settings /></ListItemIcon>
          {open && <ListItemText primary="Manage Product" />}
        </ListItem>
        <Divider />
        <ListItem button component={Link} to="/table/banka_home_banner/Home%20Banner/landscape">
          <ListItemIcon><Settings /></ListItemIcon>
          {open && <ListItemText primary="Manage Home Banner" />}
        </ListItem>
        <Divider />
        <ListItem button component={Link} to="/table/banka_popular_banner/Popular%20Banner/landscape">
          <ListItemIcon><Settings /></ListItemIcon>
          {open && <ListItemText primary="Manage Popular Banner" />}
        </ListItem>
        <Divider />
        <ListItem button component={Link} to="/table/banka_valley_banner/Valley%20Banner/landscape">
          <ListItemIcon><Settings /></ListItemIcon>
          {open && <ListItemText primary="Manage Valley Banner" />}
        </ListItem>
        <Divider />
        <ListItem button component={Link} to="/table/banka_about_us/About Us/potrait">
          <ListItemIcon><Settings /></ListItemIcon>
          {open && <ListItemText primary="Manage About Us" />}
        </ListItem>
        <Divider />
        <ListItem button component={Link} to="/table/banka_director/Manage Director/landscape">
          <ListItemIcon><Settings /></ListItemIcon>
          {open && <ListItemText primary="Manage Director" />}
        </ListItem>
        <Divider />
        
        <ListItem button component={Link} to="/table/banka_event_data/Event Page Text/landscape">
          <ListItemIcon><Settings /></ListItemIcon>
          {open && <ListItemText primary="Manage Event Page Text" />}
        </ListItem>
        <Divider />
        <ListItem button component={Link} to="/table/banka_event_participation/Event Participation/landscape">
          <ListItemIcon><Settings /></ListItemIcon>
          {open && <ListItemText primary="Manage Event Participation" />}
        </ListItem>
        <Divider />
        <ListItem button component={Link} to="/table/banka_event_snapshots/Event Snaps/landscape">
          <ListItemIcon><Settings /></ListItemIcon>
          {open && <ListItemText primary="Manage Event Snaps" />}
        </ListItem>
        <Divider />
        <ListItem button component={Link} to="/view/banka_career_applications/Career /landscape">
          <ListItemIcon><Settings /></ListItemIcon>
          {open && <ListItemText primary="Manage Career Data" />}
        </ListItem>
        <Divider />
        <ListItem button component={Link} to="/view/banka_connect_requests/Connect /landscape">
          <ListItemIcon><Settings /></ListItemIcon>
          {open && <ListItemText primary="Manage Connect Data" />}
        </ListItem>
        <Divider />
        
        
       
        
        
        
        
        <ListItem button onClick={onClose}>
          <ListItemIcon><Logout /></ListItemIcon>
          {open && <ListItemText primary="Logout" />}
        </ListItem>
      </List>
    </Drawer>
  );
};

export default Sidebar;
